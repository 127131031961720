<template>
  <main class="container">
    <ContentHeader
      :title="content_header.title"
      :subTitle="content_header.subTitle"
    />

    <div class="page__body">
      <div class="page__body__header mb5">
        <div class="w-20" style="float: right">
          <buttonwebhook
            type="button"
            class="
              button
              ml-auto
              page__body__header__button
              button--black
              text--capital
            "
            @click="toggleNavigationModal"
          >
            <span class="d-inline-flex mr-2">add notification</span>
            <ion-icon name="add-outline" class="text--regular"></ion-icon>
          </buttonwebhook>
        </div>
      </div>

      <div class="mt5" style="width: 45%">
        <div class="form__item">
          <input
            type="text"
            class="input form__input form__input--lg"
            v-model="EventName"
            @input="performSearch"
            id="searchProduct"
            placeholder="Search for Notification Name"
            style="height: 40px"
          />
        </div>
      </div>

      <div class="mt5 w-100" style="overflow-x: scroll">
        <table class="customtable _customtable">
          <thead>
            <tr>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                name
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                type
              </th>
              <!-- <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                destination
              </th> -->
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                target
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                subject
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                body
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                state
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                send date
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
              >
                actions
              </th>
            </tr>
          </thead>
          <tbody v-if="loading">
            <tr>
              <td colspan="5">
                <div class="d-flex align-items-center justify-content-center p5">
                  <div
                    class="spinner-border"
                    style="width: 3rem; height: 3rem"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span
                    class="text--black text--600 text--medium d-inline-flex ml-4"
                    >Please wait</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="notifications.data.length">
            <tr
              v-for="(notification, index) in notifications.data"
              :key="index"
            >
              <td>
                <div class="align-self-center">
                  <span
                    class="table__body__text table__text py-1 d-block text--upper"
                    style="word-break: break-word"
                  >
                    {{ notification.eventName }}
                  </span>
                </div>
              </td>
              <td>
                <div class="align-self-center">
                  <span
                    class="table__body__text table__text py-1 d-block text--upper"
                  >
                    {{ notification.notificationType }}
                  </span>
                </div>
              </td>
              <!-- <td>
                <span class="table__body__text table__text text--capital">{{
                  notification.destination
                }}</span>
              </td> -->
              <td>
                <span class="table__body__text table__text text--capital">{{
                  notification.notificationTarget
                }}</span>
              </td>
              <td>
                <span 
                  class="table__body__text table__text text--capital"
                  style="word-break: break-word; padding-right: 25px"
                >{{
                  notification.subject
                }}</span>
              </td>
              <td>
                <span 
                  class="table__body__text table__text text--capital"
                  style="word-break: break-word; padding-right: 25px"
                >{{
                  notification.messageBody
                }}</span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  notification.state
                }}</span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  notification.sendDate | moment
                }}</span>
              </td>
              <td>
                <div class="align-self-center dropdown">
                  <button
                    class="
                      button button--grey
                      text--capital text--small
                      d-flex
                      align-items-center
                    "
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    action
                    <span class="dropdown__icon">
                      <ion-icon name="caret-down-outline"></ion-icon>
                    </span>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a
                      class="dropdown-item text--capital"
                      @click="openEditNotificationModal(notification)"
                      >edit</a
                    >
                    <!-- <a class="dropdown-item text--capital">activate</a> -->
                    <a
                      class="dropdown-item text--capital"
                      @click="openDeleteTemplateModal(notification)"
                      >delete</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5">
                <div
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    p5
                  "
                >
                  <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                  <span
                    class="d-block text--danger text--600 text--medium mt-3 mb-5"
                    >No data available</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      <Pagination
        v-if="notifications"
        :allItems="notifications"
        :size="size"
        @update-created-task="changeLength"
        @change-page-index="changeCurrentPage"
      />

      <!-- Create Notification -->
      <createNotification
        :show="showCreateNotification"
        :populatedNotification="populatedNotification"
        :isEditing="isEditing"
        @error-sending-notification="errorSendingNotification"
        @sent-notification-successfully="sendNotificationSuccessfully"
        @close="closeNotificationModal"
      />

      <!-- Alert notification -->
      <div class="toaster show">
        <div
          class="toaster__container"
          v-if="alert.show"
          v-bind:class="{
            'toaster--success': alert.status === 'success',
            'toaster--error': alert.status === 'fail',
          }"
        >
          <div class="text-right">
            <span class="fa fa-times" @click="alert.show = false"></span>
          </div>
          <div>
            <span class="d-block text--small text--600 text--white">{{
              alert.title
            }}</span>
            <span class="d-block text--tiny text--400 text--white">{{
              alert.description
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal
      :show="showDeletedTemplate"
      @close="closeDeletedTemplate"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">Notification</h5>
            <p>Please confirm that you want to delete this notification</p>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--md modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeDeletedTemplate"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes fitrem"
                v-if="loadingTemplate"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes modal__confirm__button--delete fitrem"
                @click="deleteNotification"
                v-else
              >
                confirm
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>
  </main>
</template>
<script>
import { GET_ALL_NOTIFICATIONS, DELETE_NOTIFICATION } from "@/core/services/store/notification.module";
import { mapState } from "vuex";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import moment from "moment";
export default {
  name: "notifications",
  data() {
    return {
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Deposit Product has been created",
      },
      content_header: {
        title: "Notifications",
        subTitle: `Create and Manage notifications.`,
      },
      showCreateNotification: false,
      pages: 1,
      size: 20,
      populatedNotification: {},
      isEditing: false,
      loading: false,
      showDeletedTemplate: false,
      deleteTemplateValue: "",
      loadingTemplate: false,
      EventName: "",
    };
  },
  components: {
    createNotification: () =>
      import(
        /* WebpackChunkName: "createNotification" */ "@/view/pages/notifications/createNotification.vue"
      ),
    Pagination: () =>
      import(
        /* WebpackChunkName: "Pagination" */ "@/components/UI/pagination.vue"
      ),
    DeleteModal,
  },
  computed: {
    ...mapState({
      notifications: (state) => {
        return state.notification.allNotifs?.data;
      }
    }),
  },
  methods: {
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    closeNotificationModal() {
      this.populatedNotification = {};
      this.isEditing = false;
      this.toggleNavigationModal();
    },
    toggleNavigationModal() {
      this.showCreateNotification = !this.showCreateNotification;
    },
    deleteNotification() {
      this.loadingTemplate = true;
      this.$store
        .dispatch(DELETE_NOTIFICATION, { notificationKey: this.deleteTemplateValue.notificationKey })
        .then(() => {
          this.loadingTemplate = false;
          this.alertSuccess("Notification was successfully deleted.");
          this.getAllNotifications();
          this.closeDeletedTemplate();
        });
    },
    openDeleteTemplateModal(value) {
      this.deleteTemplateValue = value;
      this.showDeletedTemplate = true;
    },
    closeDeletedTemplate() {
      this.deleteTemplateValue = "";
      this.showDeletedTemplate = false;
    },
    sendNotificationSuccessfully(value) {
      if (!this.isEditing) {
        this.alertSuccess(value);
        this.getAllNotifications();
      } else {
        this.alertSuccess("Notification successfully updated");
        this.getAllNotifications();
      }
    },
    errorSendingNotification(value) {
      // if(this.isEditing) return this.alertError(value);
      this.alertError(value);
    },
    performSearch() {
      this.getAllNotifications();
    },
    getAllNotifications() {
      this.loading = true;
      this.$store.dispatch(GET_ALL_NOTIFICATIONS, {
        pageindex: this.pages,
        pagesize: this.size,
        EventName: this.EventName,
      })
        .then(() => {
          this.loading = false;
        });
    },
    openEditNotificationModal(value) {
      this.populatedNotification = value;
      this.isEditing = true;
      this.toggleNavigationModal();
    },

    changeCurrentPage(pages = null, pageSize) {
      if (pages === "next") {
        this.pages++;
        this.size = pageSize;
        this.getAllNotifications();
      } else if (pages === "previous") {
        this.pages--;
        this.size = pageSize;
        this.getAllNotifications();
      } else if (pages !== null) {
        this.parameters.pageIndex = pages;
        this.pages = pages;
        this.size = pageSize;
        this.getAllNotifications();
      }
    },

    changeLength(value) {
      this.size = value;
      this.getAllNotifications();
    },
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
  created() {
    this.getAllNotifications();
  },
};
</script>
<style scoped>
.page__tabs1 {
  grid-template-columns: repeat(5, 1fr) !important;
}
._customtable {
  width: 100%;
}

._customtable thead th:first-of-type {
  width: 190px;
}
.display {
  display: flex;
  justify-content: flex-end;
}
</style>
